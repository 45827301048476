import { IsAllowedDomain, SanitizeRedirectProps } from './sanitize-redirect.models';
import { EnvironmentData } from '../http-requests/get-environment';

const getAllowedDomainList = ({ allowedDomains }: { allowedDomains: EnvironmentData['allowedDomains'] }) => {
  return allowedDomains.split(',').map((url) => {
    try {
      return new URL(url).hostname;
    } catch {
      return '';
    }
  });
};

const isAllowedDomain = ({ hostname, domain }: IsAllowedDomain) => {
  return hostname === domain || hostname.endsWith(`.${domain}`);
};

const sanitizeRedirect = ({ redirect, allowedDomains }: SanitizeRedirectProps) => {
  if (!redirect || !allowedDomains) {
    return null;
  }

  const allowedDomainList = getAllowedDomainList({ allowedDomains });

  try {
    const redirectUrl = new URL(redirect);
    const { hostname } = redirectUrl;

    if (!allowedDomainList.some((domain) => isAllowedDomain({ hostname, domain }))) {
      return null;
    }

    return redirectUrl.toString();
  } catch {
    return null;
  }
};

export default sanitizeRedirect;
