import { v4 as uuidv4 } from 'uuid';

import { BuildOnboardingPageUrlProps } from './build-onboarding-page-url.models';

function buildOnboardingPageUrl({
  onboardingProcessPageUrl,
  authProcessId,
  isOnboardingProcessEnabled,
}: BuildOnboardingPageUrlProps): string | null {
  if (!isOnboardingProcessEnabled) {
    return null;
  }

  try {
    const url = new URL(onboardingProcessPageUrl);

    url.searchParams.append('aupid', authProcessId);
    url.searchParams.append('onpid', uuidv4());

    return url.href;
  } catch {
    return null;
  }
}

export default buildOnboardingPageUrl;
