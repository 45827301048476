'use client';

import { ABTestsProvider } from '@grupa-pracuj/react-abtesting';
import { ReactNode } from 'react';

export type ClientAbTestProviderProps = {
  children: ReactNode;
};

export default function ClientAbTestProvider({ children }: ClientAbTestProviderProps) {
  return <ABTestsProvider>{children}</ABTestsProvider>;
}
