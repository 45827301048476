'use client';

import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { useCookies } from 'next-client-cookies';
import {
  CurrentStepInApplicationProcessPayload,
  CurrentStepInAuthProcessPayload,
} from '@grupa-pracuj/tracker-relax/dist/events';
import { TestCases, useABTests } from '@grupa-pracuj/react-abtesting';
import { ApplicationStepKinds, EventSource } from '@grupa-pracuj/tracker-relax';

import { useAnalytics } from 'src/analytics';
import useEnvironment from 'src/context/EnvironmentContext';
import useQueryParameters from 'src/hooks/use-query-parameters';
import buildOnboardingPageUrl from 'src/utils/build-onboarding-page-url';
import webVitals from 'src/utils/web-vitals/web-vitals';
import { PageAnalyticsProps } from './PageAnalytics.models';

function PageAnalytics({
  serverAnalytics,
  isLogout,
  sendTestGroupAnalitics,
  currentStepInApplicationProcess,
  previousStepInApplicationProcess,
}: PageAnalyticsProps) {
  const router = useRouter();
  const cookies = useCookies();
  const {
    authProcessId,
    accountType,
    processType,
    processCurrentStage,
    applicationProcessId,
    attachedCV,
    offerId,
    oneClickApplyStatus,
    v2,
    v3,
  } = useAnalytics();
  const { onboardingProcessCookieName, cookieDomain, loginRedirectUrl, onboardingProcessPageUrl } = useEnvironment();
  const { returnUrl } = useQueryParameters();
  const ABTests = useABTests();

  const ABTest = ABTests.find((test) => test.testValue !== TestCases.absent);
  const isApplicationProcess = [applicationProcessId, attachedCV, offerId, oneClickApplyStatus].some(Boolean);
  const isExternalProviderLoginProcess =
    processType === 'sign-in' && ['facebook', 'google', 'apple'].includes(accountType);
  const shouldSendCurrentStepInApplicationProcess =
    isApplicationProcess &&
    [!!currentStepInApplicationProcess, !!previousStepInApplicationProcess, isExternalProviderLoginProcess].find(
      Boolean,
    );
  const isOnboardingProcessEnabled = processType === 'sign-up' && accountType === 'email';

  useEffect(() => {
    isLogout && v3.analytics.reset();
    v3.analytics.page();
    v2.sendPageView();
    // webVitals(v3.sendWebVitals);
    webVitals(v2.sendWebVitals);

    if (ABTest && sendTestGroupAnalitics) {
      v3.sendPageViewTestGroup({
        page: EventSource.AuthPage,
        testGroup: ABTest.testValue,
        testID: String(ABTest.testId),
      });
      v2.sendPageViewTestGroup({
        page: EventSource.AuthPage,
        testGroup: ABTest.testValue,
        testID: String(ABTest.testId),
      });
    }

    if (shouldSendCurrentStepInApplicationProcess) {
      const currentStepInApplicationProcessPayload: Omit<
        CurrentStepInApplicationProcessPayload,
        'eRecruiterApplicationProcessId'
      > = {
        currentStep: currentStepInApplicationProcess ?? ApplicationStepKinds.AuthLogin,
        previousStep: previousStepInApplicationProcess ?? ApplicationStepKinds.AuthFirstStep,
        applicationProcessId: applicationProcessId!,
        offerId: offerId!,
        attachedCV,
        oneClickApply: oneClickApplyStatus!,
      };

      v3.sendCurrentStepInApplicationProcess(currentStepInApplicationProcessPayload);
      v2.sendCurrentStepInApplicationProcess(currentStepInApplicationProcessPayload);
    }

    if (serverAnalytics) {
      const currentStepInAuthProcessPayload: CurrentStepInAuthProcessPayload = {
        authProcessId,
        processCurrentStage: processCurrentStage || 'account-confirmed-and-logged-in',
        accountType,
        processType,
      };

      const onboardingPageUrl = buildOnboardingPageUrl({
        onboardingProcessPageUrl,
        authProcessId,
        isOnboardingProcessEnabled,
      });

      const defaultRedirect = [returnUrl, onboardingPageUrl].find(Boolean) ?? loginRedirectUrl;

      if (isOnboardingProcessEnabled) {
        cookies.set(onboardingProcessCookieName, '1', { domain: cookieDomain, secure: true });
      }

      const callback = () => {
        // router.push(defaultRedirect);
        v2.setTrackerCallback(() => router.push(defaultRedirect));
        v2.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload);
      };

      v3.sendCurrentStepInAuthProcess(currentStepInAuthProcessPayload, callback);
    }
  }, []);

  return null;
}

export default PageAnalytics;
