'use client';

import { createContext, useContext, ReactNode } from 'react';

import { OfferData } from 'src/utils/http-requests/get-offer';

export type OfferContextProviderProps = {
  children: ReactNode;
  data: OfferData | null;
};

export const OfferContext = createContext<OfferData | null>(null);

export function OfferContextProvider({ children, data }: OfferContextProviderProps) {
  return <OfferContext.Provider value={data}>{children}</OfferContext.Provider>;
}

export default function useOffer(): OfferData | null {
  return useContext(OfferContext) ?? null;
}
